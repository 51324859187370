import React, { ChangeEvent, memo, useCallback, KeyboardEvent } from "react";
import { FormControl, Grid, OutlinedInput, Typography } from "@mui/material";
import { horizontalFormControlGridLeftStyle, horizontalFormControlGridRightStyle, requiredLabelStyle } from "@styles/global-css-class";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { StyledFormLabel } from "@components/Common";
import { DOT, EDITING_KEYS, NAVIGATION_KEYS, NUMBER_VALID_KEYS } from "@constants/common";
function TextFormControl({
  label,
  value,
  name,
  defaultValue,
  placeHolder,
  onlyNumber,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  formState,
  isNumberInput,
  decimalDigits = 0,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: Readonly<IFormControlProperty>) {
  const filterNonDigitAndDecimalPart = (inputValue: string) => {
    inputValue = `${inputValue}`;
    const dotIndex = inputValue.indexOf(".");
    if (dotIndex != undefined && dotIndex !== -1) {
      const decimalPart = inputValue.substring(dotIndex + 1);
      if (decimalPart.length > decimalDigits) {
        inputValue = inputValue.substring(0, dotIndex + decimalDigits + 1);
      }
    }
    return inputValue;
  };
  const formatDecimalNumberStringValue = (filteredValue: string) => {
    let formatValue = parseFloat(filteredValue);
    if (decimalDigits > 0) {
      return formatValue.toFixed(decimalDigits);
    }
    return formatValue.toFixed(0);
  };
  const handleInputRender = (value: string | undefined) => {
    if (isNumberInput && value !== undefined) {
      const filteredValue = filterNonDigitAndDecimalPart(value);
      return filteredValue;
    }
    return value ?? '';
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (isNumberInput) {
      inputValue = filterNonDigitAndDecimalPart(inputValue);
    }
    onChange(inputValue);
  };
  const handleInputBlur = (value: string | undefined) => {
    if (isNumberInput) {
      value = value !== undefined && value !== "" && value !== null ? value : "0";
      const filteredValue = filterNonDigitAndDecimalPart(value);
      const formatValue = formatDecimalNumberStringValue(filteredValue);
      if (formatValue !== "") {
        onChange(formatValue);
      }
    }
  };
  const handleNumberKey = useCallback((e: KeyboardEvent) => {
    const numValidKeys = [...NUMBER_VALID_KEYS, DOT, ...NAVIGATION_KEYS, ...EDITING_KEYS];
    if ((isNumberInput || onlyNumber) && !numValidKeys.includes(e.key)) {
      return e.preventDefault();
    }
  }, []);
  const decimalPlaceholder = isNumberInput ? 0 .toFixed(decimalDigits ?? 0) : "";
  return <Grid container item xs={xsFormControl} md={mdFormControl} data-sentry-element="Grid" data-sentry-component="TextFormControl" data-sentry-source-file="TextFormControl.tsx">
		<FormControl fullWidth margin='dense' {...props} data-sentry-element="FormControl" data-sentry-source-file="TextFormControl.tsx">
			<Grid container data-sentry-element="Grid" data-sentry-source-file="TextFormControl.tsx">
				<Grid item xs={xsLabel} sx={itemSx ?? horizontalFormControlGridLeftStyle} data-sentry-element="Grid" data-sentry-source-file="TextFormControl.tsx">
					<StyledFormLabel htmlFor={name} aria-describedby={name} hidden={isHidden} error={formState?.errors[name] !== undefined} sx={textSx ?? {
            ...requiredLabelStyle,
            textAlign: textAlign ?? "right"
          }} data-sentry-element="StyledFormLabel" data-sentry-source-file="TextFormControl.tsx">
						{label}
						{isRequired && <Typography style={{
              color: "red",
              fontSize: "18px"
            }}>
								*
							</Typography>}
					</StyledFormLabel>
				</Grid>
				<Grid item xs={xsInput ?? (xsLabel === 12 ? 12 : 12 - xsLabel)} sx={itemSx ?? horizontalFormControlGridRightStyle} data-sentry-element="Grid" data-sentry-source-file="TextFormControl.tsx">
					<OutlinedInput id={name} name={name} value={handleInputRender(value ?? defaultValue)} onChange={handleInputChange} onBlur={e => {
            handleInputBlur(value);
          }} onKeyDown={e => handleNumberKey(e)} hidden={isHidden} disabled={isDisabled ?? false} error={formState?.errors[name] !== undefined} placeholder={placeHolder ?? decimalPlaceholder} type='text' size='small' spellCheck sx={{
            width: "100%"
          }} data-sentry-element="OutlinedInput" data-sentry-source-file="TextFormControl.tsx" />
				</Grid>
				{formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
			</Grid>
		</FormControl>
	</Grid>;
}
export default memo(TextFormControl);