import { FormControl, Grid, TextField } from "@mui/material";
import React, { ChangeEvent, memo, useCallback, useEffect, useRef, KeyboardEvent } from "react";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { DOT, EDITING_KEYS, NAVIGATION_KEYS, NUMBER_VALID_KEYS } from "@constants/common";
import { customDisabledOutlineInput, paddingX1Props } from "@styles/global-css-class";
function NewTextFormControl({
  childKey,
  label,
  value,
  name,
  defaultValue,
  placeHolder,
  onlyNumber,
  isRequired,
  isDisabled,
  isHidden = false,
  onChange,
  formState,
  isNumberInput,
  decimalDigits = 0,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  isExpanded,
  ...props
}: Readonly<IFormControlProperty>) {
  const filterNonDigitAndDecimalPart = (inputValue: string) => {
    inputValue = `${inputValue}`;
    const dotIndex = inputValue.indexOf(".");
    if (dotIndex != undefined && dotIndex !== -1) {
      const decimalPart = inputValue.substring(dotIndex + 1);
      if (decimalPart.length > decimalDigits) {
        inputValue = inputValue.substring(0, dotIndex + decimalDigits + 1);
      }
    }
    return inputValue;
  };
  const formatDecimalNumberStringValue = (filteredValue: string) => {
    let formatValue = parseFloat(filteredValue);
    if (decimalDigits > 0) {
      return formatValue.toFixed(decimalDigits);
    }
    return formatValue.toFixed(0);
  };
  const handleInputRender = (value: string | undefined) => {
    if (isNumberInput && value !== undefined) {
      const filteredValue = filterNonDigitAndDecimalPart(value);
      return filteredValue;
    }
    return value ?? '';
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    if (isNumberInput) {
      inputValue = filterNonDigitAndDecimalPart(inputValue);
    }
    onChange(inputValue);
  };
  const handleInputBlur = (value: string | undefined) => {
    if (isNumberInput) {
      value = value !== undefined && value !== "" && value !== null ? value : "0";
      const filteredValue = filterNonDigitAndDecimalPart(value);
      const formatValue = formatDecimalNumberStringValue(filteredValue);
      if (formatValue !== "") {
        onChange(formatValue);
      }
    }
  };
  const handleNumberKey = useCallback((e: KeyboardEvent) => {
    const numValidKeys = [...NUMBER_VALID_KEYS, DOT, ...NAVIGATION_KEYS, ...EDITING_KEYS];
    if ((isNumberInput || onlyNumber) && !numValidKeys.includes(e.key)) {
      return e.preventDefault();
    }
  }, []);
  const decimalPlaceholder = isNumberInput ? 0 .toFixed(decimalDigits ?? 0) : "";
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewTextFormControl" data-sentry-source-file="NewTextFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} data-sentry-element="FormControl" data-sentry-source-file="NewTextFormControl.tsx">
			<TextField type='text' id={name} key={`${childKey}-input`} name={name} placeholder={placeHolder ?? decimalPlaceholder} error={formState?.errors[name] !== undefined} inputProps={{
        ref: inputRef
      }} disabled={isDisabled ?? false} hidden={isHidden} required={isRequired} label={label} spellCheck value={handleInputRender(value ?? defaultValue)} onChange={handleInputChange} onBlur={e => {
        handleInputBlur(value);
      }} onKeyDown={e => handleNumberKey(e)} sx={{
        width: "100%",
        ...customDisabledOutlineInput
      }} data-sentry-element="TextField" data-sentry-source-file="NewTextFormControl.tsx" />
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewTextFormControl);