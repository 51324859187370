export function decodeToken<T = object>(token: string): T | null {
    try {
        if (token.split('.').length !== 3 || typeof token !== 'string') {
            return null;
        }
        const payload: string = token.split('.')[1];
        const decoded = JSON.parse(Buffer.from(payload, 'base64').toString())
        return decoded;
    } catch (error) {
        console.error(error)
        return null;
    }
}
export function isTokenExpired(token: string): boolean {
    const decodedToken: any = decodeToken(token);
    let result: boolean = true;
    if (decodedToken?.exp) {
        const expirationDate: Date = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp);
        result = expirationDate.valueOf() < new Date().valueOf();
    }

    return result;
}