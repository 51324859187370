import { FormControl, FormControlLabel, Grid } from "@mui/material";
import { memo } from "react";
import { ToggleSwitch } from "@components/Common";
import { IFormControlProperty } from "@definitions/index";
import { FieldError } from "@components/FieldError";
import { paddingX1Props } from "@styles/global-css-class";
const NewToggleFormControl = ({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  formState,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  ...props
}: IFormControlProperty) => {
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewToggleFormControl" data-sentry-source-file="NewToggleFormControl.tsx">
        <FormControl fullWidth margin='normal' sx={{
      justifyContent: "center"
    }} {...props} data-sentry-element="FormControl" data-sentry-source-file="NewToggleFormControl.tsx">
            <FormControlLabel id={`${name}-label`} disabled={isDisabled} hidden={isHidden} required={isRequired} control={<ToggleSwitch id={`${name}-switch`} name={name} checked={value ?? defaultValue ?? false} value={name} onChange={(e: any, checked: boolean) => {
        onChange(checked);
      }} />} label={label} sx={{
        "& .MuiTypography-root": {
          pl: 1
        },
        p: "8px"
      }} data-sentry-element="FormControlLabel" data-sentry-source-file="NewToggleFormControl.tsx" />
            {formState && <FieldError formState={formState} name={name} textAlign={textErrorAlign} />}
        </FormControl>
    </Grid>;
};
export default memo(NewToggleFormControl);