import { Box, FormHelperText } from "@mui/material";
import { FieldErrorProps } from "@definitions/index";
const FieldError = ({
  formState,
  name,
  textAlign,
  childKey
}: FieldErrorProps) => {
  return <Box key={`${childKey ?? name}-error-box`} sx={{
    width: "100%"
  }} id={`${name}-error`} aria-live='polite' aria-atomic='true' data-sentry-element="Box" data-sentry-component="FieldError" data-sentry-source-file="FieldError.tsx">
        {formState?.errors[name]?.map((error, index) => <FormHelperText sx={{
      textAlign: textAlign ?? 'left'
    }} error key={`${childKey ?? name}-${error}-${index}-text`}>
                    {error}
                </FormHelperText>)}
    </Box>;
};
export { FieldError };