import { FormControl, Grid, Select, MenuItem, InputLabel } from "@mui/material";
import React, { memo, useRef, useEffect } from "react";
import { menuItemStyle, paddingX1Props, selectMenuPropStyle } from "@styles/global-css-class";
import { FieldError } from "@components/FieldError";
import { IDropdownFormControlProperty } from "@definitions/index";
function NewDropdownFormControl({
  childKey,
  label,
  name,
  value,
  defaultValue,
  onChange,
  isRequired,
  isDisabled,
  isHidden,
  showSelect,
  listValues,
  placeHolder,
  formState,
  zIndex,
  xsFormControl = 12,
  mdFormControl = 12,
  xsLabel = 6,
  xsInput,
  textAlign,
  textErrorAlign,
  itemSx,
  textSx,
  errorName,
  isExpanded,
  valueDefaultSelect,
  isDisableDefaultSelect,
  inputSx,
  ...props
}: Readonly<IDropdownFormControlProperty>) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);
  return <Grid container item xs={xsFormControl} md={mdFormControl} sx={itemSx ?? {
    ...paddingX1Props,
    alignContent: 'flex-start'
  }} data-sentry-element="Grid" data-sentry-component="NewDropdownFormControl" data-sentry-source-file="NewDropdownFormControl.tsx">
		<FormControl key={`${childKey}-form-control`} fullWidth margin='normal' {...props} required={isRequired} data-sentry-element="FormControl" data-sentry-source-file="NewDropdownFormControl.tsx">
			<InputLabel key={`${childKey}-form-control-input-label`} id={name} sx={textSx ?? {}} error={formState?.errors[name] !== undefined} data-sentry-element="InputLabel" data-sentry-source-file="NewDropdownFormControl.tsx">
				{label}
			</InputLabel>
			<Select key={`${childKey}-form-control-select`} labelId={name} id={name} name={name} label={label} required={isRequired} hidden={isHidden} value={value ?? defaultValue ?? ' '} error={formState?.errors[name] !== undefined} inputProps={{
        ref: inputRef
      }} onChange={e => {
        onChange(e.target.value);
      }} sx={inputSx ?? {}} disabled={isDisabled} MenuProps={selectMenuPropStyle(zIndex ?? 99999)} data-sentry-element="Select" data-sentry-source-file="NewDropdownFormControl.tsx">
				{showSelect && <MenuItem disabled={isDisableDefaultSelect} sx={{
          ...menuItemStyle,
          fontStyle: "italic"
        }} key={`${childKey}-9999-null`} value={valueDefaultSelect ?? ' '}>
						{placeHolder ?? `Select ${label}`}
					</MenuItem>}
				{listValues?.map((option, idx) => {
          idx += 1;
          return typeof option === "object" ? <MenuItem sx={menuItemStyle} key={`${childKey}-${idx}-${option.value}`} value={option.value}>
						{option.name}
					</MenuItem> : <MenuItem sx={menuItemStyle} key={`${childKey}-${idx}-${option}`} value={option}>
						{option}
					</MenuItem>;
        })}
			</Select>
			{formState && <FieldError key={`${childKey}-form-control-error`} formState={formState} name={errorName ?? name} textAlign={textErrorAlign} />}
		</FormControl>
	</Grid>;
}
export default memo(NewDropdownFormControl);